<template>
	<div>
		<el-row>
			<el-date-picker
					v-model="examDate"
					type="daterange"
					format="yyyy 年 MM 月 dd 日"
					value-format="yyyy-MM-dd"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="handleDateChange"
			>
			</el-date-picker>
			<el-col :span="24" style="margin-bottom: 20px;">
				<el-form :model="allData" :inline="true" style="display: flex;justify-content: space-between">
<!--					<el-form-item >-->
<!--						<template scope="scope">-->
<!--							<div>-->
<!--								{{'课时达标'}}-->
<!--							</div>-->
<!--							<div>-->
<!--								<span style="font-size: 25px">{{allData?allData.StudyTimeNum:'&#45;&#45;'}}{{'人'}}</span>-->
<!--							</div>-->
<!--							<div>-->
<!--								{{'总人数：'}}{{allData?allData.StudyTimeNumCount:'&#45;&#45;'}}-->
<!--							</div>-->
<!--						</template>-->
<!--					</el-form-item>-->
					<el-form-item >
						<template scope="scope">
							<div>
								{{'录播视频'}}
							</div>
							<div>
								<span style="font-size: 25px">{{allData?allData.VideoTimeNum:'--'}}{{'人'}}</span>
							</div>
							<div>
								{{'总人数：'}}{{allData?allData.VideoTimeNumCount:'--'}}
							</div>
						</template>
					</el-form-item>
					<el-form-item >
						<template scope="scope">
							<div>
								{{'自由练习'}}
							</div>
							<div>
								<span style="font-size: 25px">{{allData?allData.OpenQuestionNum:'--'}}{{'人'}}</span>
							</div>
							<div>
								{{'总人数：'}}{{allData?allData.OpenQuestionNumCount:'--'}}
							</div>
						</template>
					</el-form-item>
					<el-form-item >
						<template scope="scope">
							<div>
								{{'模拟考试'}}
							</div>
							<div>
								<span style="font-size: 25px">{{allData?allData.StudyExamSum:'--'}}{{'人'}}</span>
							</div>
							<div>
								{{'总人数：'}}{{allData?allData.StudyExamSumCount:'--'}}
							</div>
						</template>
					</el-form-item>
					<el-form-item >
						<template scope="scope">
							<div>
								{{'取证人数'}}
							</div>
							<div>
								<span style="font-size: 25px">{{allData?allData.ExamPassNum:'--'}}{{'人'}}</span>
							</div>
							<div>
								{{'总人数：'}}{{allData?allData.ExamPassNumCount:'--'}}
							</div>
						</template>
					</el-form-item>
<!--					<el-form-item >-->
<!--						<template scope="scope">-->
<!--							<div>-->
<!--								{{'学习进度达50%'}}-->
<!--							</div>-->
<!--							<div>-->
<!--								<span style="font-size: 25px">{{allData?allData.StudyFiftyNum:'&#45;&#45;'}}{{'人'}}</span>-->
<!--							</div>-->
<!--							<div>-->
<!--								{{'总人数：'}}{{allData?allData.StudyFiftyNumCount:'&#45;&#45;'}}-->
<!--							</div>-->
<!--						</template>-->
<!--					</el-form-item>-->
<!--					<el-form-item >-->
<!--						<template scope="scope">-->
<!--							<div>-->
<!--								{{'学习进度达80%'}}-->
<!--							</div>-->
<!--							<div>-->
<!--								<span style="font-size: 25px">{{allData?allData.StudyEightyNum:'&#45;&#45;'}}{{'人'}}</span>-->
<!--							</div>-->
<!--							<div>-->
<!--								{{'总人数：'}}{{allData?allData.StudyEightyNumCount:'&#45;&#45;'}}-->
<!--							</div>-->
<!--						</template>-->
<!--					</el-form-item>-->
				</el-form>
			</el-col>
			<el-col :span="24">
				<el-form :inline="true">
					<el-form-item label="关键字:">
						<el-input
								v-model="filters.keyWord"
								placeholder="请输入姓名/手机号"
								clearable
						>
						</el-input>
					</el-form-item>
					<el-form-item label='单位名称:'>
						<el-input v-model='filters.unitName' placeholder='请输入单位信息' clearable></el-input>
					</el-form-item>
					
					<el-form-item label='岗位名称:'>
						<el-select v-model="filters.stationName" placeholder="请选择" clearable>
							<el-option
									v-for="item in stationName"
									:key="item.Id"
									:label="item.Name"
									:value="item.Id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label='行业名称:'>
						<el-select v-model="filters.trateName" placeholder="请选择" clearable>
							<el-option
									v-for="item in trateName"
									:key="item.Id"
									:label="item.Name"
									:value="item.Id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label='报考日期:'>
						<el-date-picker
								v-model="filters.examDate"
								type="daterange"
								format="yyyy 年 MM 月 dd 日"
								value-format="yyyy-MM-dd"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
						>
						</el-date-picker>
					</el-form-item>
					<el-form-item label='是否取证:'>
						<el-select v-model="filters.isEnable" placeholder="请选择" clearable>
							<el-option
									v-for="item in isEnableArr"
									:key="item.value"
									:label="item.label"
									:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item>
						<toolbar
								:buttonList="buttonList"
								@callFunction="callFunction"
						></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
			
		</el-row>
		
		<!-- 表格主体 -->
		<el-table
				border
				highlight-current-row
				:data="tableData"
				v-loading="listLoading"
				:cell-style='rowClass'
				:header-cell-style='headerClass'
				ref="multipleTable"
				@selection-change="handleSelectChange"
				style="width: 100%"
		>
			<!-- <el-table-column type='index' label='序号' width='60'></el-table-column> -->
			<el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
			<el-table-column prop="Name" label="姓名" width='80'></el-table-column>
			<el-table-column prop="PhoneNumber" label="手机号" min-width="140"></el-table-column>
			<el-table-column prop='StationName' label='岗位名称' min-width="100"></el-table-column>
			<el-table-column prop='TradeName' label='行业名称' min-width="100"></el-table-column>
			<el-table-column prop='UnitName' label='单位名称' min-width="100"></el-table-column>
			<el-table-column prop='RealityLearnDuration' label='学习课时（课时）' min-width="100"></el-table-column>
			<el-table-column prop='VideoStudyTime' label='录播视频（分钟）' min-width="100"></el-table-column>
			<el-table-column prop='PracticeQuestionNum' label='自由练习（题）' min-width="100"></el-table-column>
			<el-table-column prop='PracticeQuestionRate' label='自由练习完成率' min-width="100">
				<template scope="scope">
					{{scope.row.PracticeQuestionRate?scope.row.PracticeQuestionRate.toFixed(2):'--'}}%
				</template>
			</el-table-column>
			<el-table-column prop='ExamQuestionNum' label='模拟考试（题）' min-width="100"></el-table-column>
			<el-table-column prop='ExaminationNum' label='模拟考试次数' min-width="100"></el-table-column>
			<el-table-column prop='UnitName' label='是否取证' >
				<template scope="scope">
					<el-tag v-if="scope.row.IsPass" type="success">是</el-tag>
					<el-tag v-else type="danger">否</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="" label="取证考试（次数）"  min-width="100">
				<template scope="scope">
					<el-button type="text" @click="viewStudentMsgDetail(scope.row)">{{scope.row.ExaminationNumPass}}</el-button>
				</template>
			</el-table-column>
			<el-table-column prop='ExaminationScoreNew' label='取证考试最新成绩（分）' min-width="100"></el-table-column>
			<el-table-column prop='ExamTime' label='报考时间' min-width="100"></el-table-column>
		</el-table>
		<!-- 分页 -->
		<el-col :span="24" class="pageBarContainer">
			<div>
				<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="this.pages.pageIndex"
						:page-sizes="[10, 20, 40, 100]"
						:page-size="this.pages.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="this.pages.dataCount"
						class='pageBar'
				>
				</el-pagination>
			</div>
		</el-col>
		<!-- 取证考试成绩 -->
		<el-dialog
				title="取证考试成绩"
				:visible.sync="examRecodeDialog"
				v-model="examRecodeDialog"
				width="70%"
				:close-on-click-modal="true"
		>
			<el-table
					border
					highlight-current-row
					:data="studyTableData"
					v-loading="studyTableLoading"
					:cell-style='rowClass'
					:header-cell-style='headerClass'
					ref="studyMultipleTable"
					style="width: 100%"
			>
<!--				<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>-->
				<el-table-column prop="TotalScore" label="成绩"></el-table-column>
				<el-table-column prop="IsQualified" label="状态">
					<template scope="scope">
						<el-tag v-if="scope.row.IsQualified" type="success">及格</el-tag>
						<el-tag v-else type="danger">不及格</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="StartTime" label="考试开始时间"></el-table-column>
				<el-table-column prop="HandTime" label="交卷时间">
					<template scope="scope">
						{{scope.row.HandTime | second}}
					</template>
				</el-table-column>
				<el-table-column prop="VideoName" label="考试用时">
					<template scope="scope">
						{{timeToDate(scope.row)}}
					</template>
				</el-table-column>
				<el-table-column prop="VideoName" label="交卷原因">
					<template slot-scope='scope'>
						<span v-if='scope.row.Status == 1 && scope.row.IsDeleted'>放弃考试</span>
						<span v-else-if='scope.row.ExceptionType == 0'>正常交卷</span>
						<span v-else-if='scope.row.ExceptionType == 1'>达到违规数</span>
						<span v-else-if='scope.row.ExceptionType == 2'>截屏</span>
						<span v-else-if='scope.row.ExceptionType == 3'>录屏或投屏</span>
						<span v-else-if='scope.row.ExceptionType == 4'>照片抓拍失败</span>
						<span v-else-if='scope.row.ExceptionType == 5'>异常操作</span>
						<span v-else-if='scope.row.ExceptionType == 6'>闪退</span>
						<span v-else-if='scope.row.ExceptionType == 7'>网络未连接</span>
						<span v-else-if='scope.row.ExceptionType == 8'>考试超时</span>
						<span v-else-if='scope.row.ExceptionType == 10'>切屏或锁屏</span>
						<span v-else>未知</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					@size-change="studySizeChange"
					@current-change="studyCurrentChange"
					:current-page="this.studyRecode.studyIndex"
					:page-sizes="[10, 20, 30, 40]"
					:page-size="this.studyRecode.studySize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="this.studyRecode.studyCount"
					class='pageBar'
			>
			</el-pagination>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="examRecodeDialog = false">取消</el-button>
				<!-- <el-button type="primary" @click.native="sendMessageSubmit" :loading="sendMessageLoading">提交</el-button> -->
			</div>
		</el-dialog>
	</div>
</template>
<script>
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
import { getButtonList } from "../../promissionRouter";
import {
	getStudentExamEnrollListPage,
	getExamEnrollInfoListPage,
	getExamEnrollInfoListPageNew,
	submitStudentInformationByManger,
	importStudentExamEnrollInfoUrl,
	exportExamEnrollInfo,
	getDataDictionaryList,
	getSocialUnitListPage,
	generateBatch,
	getExamBatchPopulationList,
	addBatch,
	updateIsExemptTrain,
	candidateSendsSMS,
	getStudentExamineeAccountExamListPage,
	getStudentExamineeAccountStudyListPage,
	getStudentExamineeAccountTrainListPage,
	getLearnRecordByEnroll,
	getAgencyInfoListPage,
	getStudentStudyArchivesListPage, getAllMyStudentExamineePaperListPage, getStudentStudyArchivesSum
} from '@/api/api'
import { validPhone,valididentityCard } from "../../../util/validate";
import util from "../../../util/date";
import api from '@/api'
export default {
	components: { Toolbar },
	data(){
		return {
			allData:[],
			examDate:[],
			stationName:[],
			trateName:[],
			// 筛选项
			filters:{
				trateName:undefined,
				stationName:undefined,
				keyWord:undefined,
				unitName:undefined,
				isEnable: undefined,
				examDate:[],
			},
			options:[
				{label:'无需缴费',value:0},
				{label:'已缴费',value:1},
				{label:'未缴费',value:2},
			],
			IsAllowExaminationArr:[
				{label: '是',value: 1},
				{label: '否',value: 0}
			],
			isEnableArr:[
				{label: '是',value: true},
				{label: '否',value: false}
			],
			trainingInstitutionsArr: [],
			// 分页
			pages:{
				pageIndex:1,
				pageSize:20,
				dataCount:0
			},
			// 表格数据
			tableData:[],
			buttonList:[],
			listLoading:false,
			
			// 查看学习记录
			examRecodeDialog: false,
			studyTableLoading:false,
			studyTableData:[],
			// 分页
			studyRecode:{
				// 学习
				studyIndex:1,
				studySize:20,
				studyCount:0,
			},
		}
	},
	methods:{
		handleDateChange(){
			this.getAllData()
		},
		// 获取默认的起始日期（7天前）
		getDefaultStartDate() {
			const date = new Date();
			date.setDate(date.getDate() - 7);
			return this.formatDate(date);
		},
		
		// 获取默认的结束日期（当天）
		getDefaultEndDate() {
			const date = new Date();
			date.setTime(date.getTime() - 24 * 60 * 60 * 1000); // 减去一天的毫秒数
			return this.formatDate(date);
		},
		
		// 格式化日期为 yyyy-MM-dd
		formatDate(date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		},
		async getAllData(){
			console.log(this.filters.examDate,'ddddddddd')
			let params = {
				examStartTime:this.examDate?this.examDate[0]:this.getDefaultStartDate(),//考试开始时间
				examEndTime:this.examDate?this.examDate[1]:this.getDefaultEndDate(),//考试结束时间
			}
			
			const res = await  getStudentStudyArchivesSum(params)
			if (res.data.Success){
				this.allData = res.data.Response
			}
		},
		timeToDate(row){
			if (row){
				// 定义两个时间字符串
				const time1 = row.HandTime;
				const time2 = row.StartTime;
				
				// 将时间字符串转换为 Date 对象
				const date1 = new Date(time1.replace(' ', 'T'));
				const date2 = new Date(time2.replace(' ', 'T'));
				
				// 计算两个时间的时间差（以毫秒为单位）
				const timeDifference = Math.abs(date1 - date2);
				
				// 将毫秒转换为秒
				const totalSeconds = Math.floor(timeDifference / 1000);
				
				// 计算小时数
				const hours = Math.floor(totalSeconds / 3600);
				// 计算剩余的秒数，用于计算分钟数
				const remainingSecondsAfterHours = totalSeconds % 3600;
				// 计算分钟数
				const minutes = Math.floor(remainingSecondsAfterHours / 60);
				// 计算最终剩余的秒数
				const seconds = remainingSecondsAfterHours % 60;
				
				// 输出结果
				const result = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
				console.log(`两个时间间隔为 ${result}`);
				return result
			}
		},
		stationList(){
			let params = {
				dataType:1,//数据类型:0:行业 1：岗位
				isEnable:true,//是否启用
				name:undefined,//名称
				projectId:parseInt(window.localStorage.getItem('projectId'))
			}
			getDataDictionaryList(params).then(res => {
				if (res.data.Success){
					console.log(res.data.Response)
					this.stationName = res.data.Response
				}
			})
		},
		trateList(){
			let params = {
				dataType:0,//数据类型:0:行业 1：岗位
				isEnable:true,//是否启用
				name:undefined,//名称
				projectId:parseInt(window.localStorage.getItem('projectId'))
			}
			getDataDictionaryList(params).then(res => {
				if (res.data.Success){
					this.trateName = res.data.Response
					console.log(res.data.Response)
				}
			})
		},
		// 列表样式
		rowClass(){
			return 'text-align:center;'
		},
		headerClass(){
			return 'text-align:center;'
		},
		// 筛选项清空重新获取数据
		handleClear(){
			this.getDataList()
		},
		// 分页处理
		handleSizeChange(value){
			this.pages.pageSize = value
			this.getDataList()
		},
		handleCurrentChange(value){
			this.pages.pageIndex = value
			this.getDataList()
		},
		// 查询
		getListData(){
			console.log(this.filters.examDate,',,,,,,data')
			this.pages.pageIndex = 1
			this.getDataList()
			this.getAllData()
		},
		// 获取表格数据
		getDataList(){
			let params = {
				keyWord:this.filters.keyWord ? this.filters.keyWord : undefined,
				unitName:this.filters.unitName ? this.filters.unitName : undefined,
				stationId:this.filters.stationName ? this.filters.stationName : undefined,
				tradeId:this.filters.trateName ? this.filters.trateName : undefined,
				isCertificate: this.filters.isEnable ,//是否有证书
				examStartTime:this.filters.examDate?this.filters.examDate[0]:undefined,//考试开始时间
				examEndTime:this.filters.examDate?this.filters.examDate[1]:undefined,//考试结束时间
				pageIndex:this.pages.pageIndex,
				pageSize:this.pages.pageSize,
			}
			this.listLoading = true
			getStudentStudyArchivesListPage(params).then((res) => {
				if (res.data.Success){
					this.tableData = res.data.Response.Data
					this.pages.dataCount = res.data.Response.DataCount
					this.listLoading = false;
					if (!this.filters.examDate){
						this.filters.examDate = [this.getDefaultStartDate(), this.getDefaultEndDate()]; // 设置
					}
				}
			})
			
		},
		// 选中表格行
		handleSelectChange(allRow){
			this.needData = allRow
		},
		callFunction(item) {
			this[item.Func].apply(this, item);
		},
		// 获取数据字典岗位、行业
		getDataDictionaryList() {
			var params = {}
			this.postTypeArr = []
			this.industryTypeArr = []
			getDataDictionaryList(params).then(res => {
				var result = res.data
				if(result.Success) {
					result.Response.forEach(item => {
						if(item.DataType == 0) {
							this.industryTypeArr.push(item)
						}
						if(item.DataType == 1) {
							this.postTypeArr.push(item)
						}
					});
				}
			})
		},
		// 学习记录
		viewStudentMsgDetail(row) {
			this.examRecodeDialog = true
			this.getStudyRecordFun(row)
		},
		// 获取学习记录列表
		getStudyRecordFun(row) {
			let params = {
				studentAccountId: row.StudentAccountId,
				pageIndex: this.studyRecode.studyIndex,
				pageSize: this.studyRecode.studySize,
			}
			this.studyTableLoading = true
			getAllMyStudentExamineePaperListPage(params).then((res) => {
				var result = res.data
				if(result.Success) {
					if(result.Response) {
						this.studyTableData = result.Response.Data
						this.studyRecode.studyCount = result.Response.DataCount
					}
				}
				this.studyTableLoading = false
			})
		},
		// 学习记录分页
		studySizeChange(value){
			this.studyRecode.studySize = value
			this.getStudyRecordFun(this.needData)
		},
		studyCurrentChange(value){
			this.studyRecode.studyIndex = value
			this.getStudyRecordFun(this.needData)
		},
		
	},
	computed: {
		headers() {
			return{
				"Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
			}
		}
	},
	watch: {
	
	},
	created(){
		this.trateList()
		this.stationList()
	},
	mounted(){
		this.filters.examDate = [this.getDefaultStartDate(), this.getDefaultEndDate()]; // 设置
		this.examDate = [this.getDefaultStartDate(), this.getDefaultEndDate()]; // 设置
		this.getAllData()
		this.getDataList()
		this.getDataDictionaryList()
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	}
}
</script>

<style lang="stylus" scoped>
// .pageBar{
//     margin-top:15px!important;
// }
.pageBar{
	display: flex;
	align-items: center;
	font-size: 14px;
	height :70px;
}
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 100%;
	height: 100%;
	display: block;
}
.social {
	width 100%;
}
.messageTitle{
	color: #999;
	font-size: 16px;
}
.messageContent {
	font-size: 16px;
	margin-top: 10px;
}
.pageBarContainer {
	display: flex;
	align-items: center;
	font-size: 14px;
}
.selectNumCla {
	color: #409EFF;
	font-weight: bold;
	margin: 0 5px;
	font-size: 16px;
}
</style>